<!-- Header -->
<!-- Web view -->
<header class="w-100 pt-2 bkcolor-cjc-header d-none d-lg-block ">
    <div class="container">
        <div class="row">
            <div class="col-10 col-lg-9">
                <div class="d-inline-block">
                    <a class="nav-item" [routerLink]="['/homepage']"><img class=" img-responsive"
                            src="assets\imgs\cjc_cat_white.webp" alt=""></a>
                </div>
                <div class="d-inline-block ml-4">
                    <ul class="list-unstyled m-0">
                        <li class="float-left mx-2 px-1" *ngFor="let menuItem of menuItems">
                            <a class="nav-item" [routerLink]="['/'+menuItem.url]" routerLinkActive="active">
                                {{ menuItem.name }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-2 col-lg-3">
                <ul class="float-right list-unstyled m-0">
                    <li class="float-left mx-1 px-0" >
                        <a class="nav-item position-relative facebook-icon mx-1" >
                            <i class="cjc-icon icon-x icon-all-icons-05"></i>
                            <div class="position-absolute dropdown">
                                <a href="https://www.facebook.com/CairoJazzClubPage/" target="_blank">CJC</a>
                                <hr>
                                <a href="https://www.facebook.com/cairojazzclub610/" target="_blank">610</a>
                            </div>
                        </a>

                        <a class="nav-item mx-1" [href]="'https://www.youtube.com/@cairojazzclub7'" target="_blank">
                            <i class="cjc-icon icon-x icon-all-icons-13"></i>
                        </a>
                        <a class="nav-item mx-1" [href]="'https://soundcloud.com/cairo-jazz-club'" target="_blank">
                            <i class="cjc-icon icon-x icon-all-icons-10"></i>
                        </a>
                        <a class="nav-item instagram-icon mx-1" >
                            <i class="cjc-icon icon-x icon-all-icons-06"></i>
                            <div class=" dropdown">
                                <a href="https://www.instagram.com/cairojazzclub/" target="_blank">CJC</a>
                                <hr>
                                <a href="https://www.instagram.com/cairojazzclub610/" target="_blank">610</a>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>
<!-- Mob view -->
<div class="w-100 py-2  bkcolor-cjc-header d-lg-none">
    <div class="container d-flex justify-content-between align-items-center">
        <a [routerLink]="['/homepage']"><i class="color-cjc-body cjc-icon icon-icons-white-03 icon-2x"></i></a>
        <h6 style="margin: 0;">{{navItem}}</h6>
        <svg (click)="toggle()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
                d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
    </div>
</div>
<!-- Nav Sidebar -->

<!--End Of Header -->
<router-outlet></router-outlet>
<!--Footer -->
<div class="w-100 py-4 bkcolor-cjc-header footer">
    <div class="container">
        <div class="row mb-3">
            <div class="col-12 col-md-6 text">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-9">
                        <div class="d-flex flex-column gap">
                            <strong>CJC</strong>
                            <div class="d-flex col-gap">

                                <i class="cjc-icon icon-x icon-all-icons-07"></i>
                                <p><a class="mb-0" href="https://goo.gl/maps/Qj9yrFAChXujoszF7" target="_blank">197A,
                                        26TH JULY ST. AGOUZA, GIZA</a></p>

                            </div>
                            <div class="d-flex col-gap">
                                <i class="cjc-icon icon-x  icon-phone"></i>
                                <a href="tel:01068804764">+20 106 880 4764</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 text">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-9">
                        <div class="d-flex flex-column gap">
                            <strong>610</strong>
                            <div class="d-flex col-gap">

                                <i class="cjc-icon icon-x icon-all-icons-07"></i>
                                <p> <a class="mb-0" href="https://goo.gl/maps/rsECPfZBFpY6aqs26" target="_blank">CJC
                                        610, AL GUEZIRA PLAZA, Sheikh Zayed, Giza</a>
                                </p>

                            </div>
                            <div class="d-flex col-gap">
                                <i class="cjc-icon icon-x  icon-phone"></i>
                                <a href="tel:01008111330">+20 100 811 1330</a>
                            </div>
                            <!-- <div class="d-flex col-gap">
                                    <i class="cjc-icon icon-x icon-mail"></i>
                                    <a href="mailto:Contact@cairojazzclub.com">Contact@cairojazzclub.com</a>
                                </div> -->
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>

            </div>

        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-center edited-gap gap">
                <i class="cjc-icon icon-x icon-mail"></i>
                <a class="fw-bold mb-2" href="mailto:Contact@cairojazzclub.com">Contact@cairojazzclub.com</a>
            </div>
            <div class="col-12 d-flex justify-content-center gap ">
                <small> <span routerLink="/homepage" style="cursor: pointer;"> COPYRIGHT © {{ currentYear }} Cairo Jazz Club LLC.</span> ALL RIGHTS RESERVED. &nbsp;&nbsp; DESIGNED & DEVELOPED BY
                    <a href="https://graspstudios.com" class="link" target="_blank">GRASPSTUDIOS</a></small>
            </div>
        </div>
    </div>
</div>
<!--End of Footer -->