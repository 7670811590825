import { Component, ElementRef, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { homeService } from 'src/app/shared/services/home.service';
import { staticsService } from 'src/app/shared/services/statics.service';
import { json } from 'express';



@Component({
  selector: 'app-maintheme',
  templateUrl: './maintheme.component.html',
  styleUrls: ['./maintheme.component.scss']
})
export class MainthemeComponent implements OnInit, AfterViewInit {

  menuItems: any = [];
  socialIcons: any = [];
  footerIcons: any = [];
  opened: boolean = false;
  currentYear: any;
  navItem: string;
  mobileTitleListener: any;

  constructor(public modalService: NgbModal, private cDR: ChangeDetectorRef, private router: Router, private service: homeService, private staticsService: staticsService) { }

  toggle() {
    const modalRef = this.modalService.open(SideNavbarComponent, {
      size: "lg",
      centered: true
    });
  }


  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    this.navItem = null;
    this.mobileTitleListener = this.staticsService.listen().subscribe((response) => {
      this.navItem = response;
      this.cDR.detectChanges();
    });
    this.currentYear = new Date().getFullYear();
    this.menuItems = [
      // {
      //   name: "Home",
      //   url: "homepage"
      // },
      {
        name: "Events/booking",
        url: "events"
      },
      {
        name: "About Cjc",
        url: "about-cjc"
      },
      {
        name: "Gallery",
        url: "gallery"
      },
      {
        name: "Careers",
        url: "careers"
      },
      {
        name: "Artist Submissions",
        url: "artist-submission"
      },
      {
        name: "House Rules",
        // url: "artist-submission"
        url: "house-rules"
      },
    ];
    this.socialIcons = [
      // {
      //   "name": "",
      //   "icon": "icon-all-icons-01",
      //   "url": ""
      // },
      {
        "name": "",
        "icon": "icon-all-icons-05",
        "url": "https://www.facebook.com/CairoJazzClubPage"
      },
      {
        "name": "",
        "icon": "icon-all-icons-06",
        "url": "https://www.instagram.com/cairojazzclub"
      },

      {
        "name": "",
        "icon": "icon-all-icons-13",
        "url": "https://www.youtube.com/@cairojazzclub7"
      },
      {
        "name": "",
        "icon": "icon-all-icons-10",
        "url": "https://soundcloud.com/cairo-jazz-club"
      },
    ];
    this.footerIcons = [
      {
        "name": "",
        "icon": "icon-all-icons-07",
        "url": ""
      },
      {
        "name": "",
        "icon": "icon-all-icons-09",
        "url": ""
      },
      {
        "name": "",
        "icon": "icon-all-icons-12",
        "url": ""
      },
      {
        "name": "",
        "icon": "icon-all-icons-08",
        "url": ""
      }
    ];
  }
}
