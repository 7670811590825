import { Routes, RouterModule } from '@angular/router';

export const PUBLIC_ROUTES: Routes = [
    {
        path: 'homepage',
        loadChildren: () =>
            import("../../website/homepage/homepage.module").then((m) => m.HomepageModule),
    },
    {
        path: 'events',
        loadChildren: () =>
            import("../../website/events/events.module").then((m) => m.EventsModule)
    },
    {
        path: 'about-cjc',
        loadChildren: () =>
            import("../../website/aboutus/aboutus.module").then((m) => m.AboutusModule)
    },
    {
        path: 'gallery',
        loadChildren: () =>
            import("../../website/gallery/gallery.module").then((m) => m.GalleryModule)
    },
    {
        path: 'careers',
        loadChildren: () =>
            import("../../website/careers/careers.module").then((m) => m.CareersModule)
    },
    {
        path: 'artist-submission',
        loadChildren: () =>
            import("../../website/contactus/contactus.module").then((m) => m.ContactusModule)
    },
    {
        path: 'house-rules',
        loadChildren: () =>
            import("../../website/houserules/houserules.module").then((m) => m.HouserulesModule)
    },
    {
        path: 'cjc_menu',
        loadChildren: () =>
            import("../../website/cjcmenu/cjcmenu.module").then((m) => m.CjcmenuModule)
    },
];
