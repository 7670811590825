import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { homeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit {
  menuItems = [];
  private element: any;


  constructor(public modalService: NgbModal , private el: ElementRef , private service : homeService) {
    this.element = el.nativeElement;
   }

  ngOnInit() {
    this.menuItems = [
      {
        name: "Home",
        url: "homepage"
      },
      {
        name: "Events",
        url: "events"
      },
      {
        name: "About Cjc",
        url: "about-cjc"
      },
      {
        name: "Gallery",
        url: "gallery"
      },
      {
        name: "Careers",
        url: "careers"
      },
      {
        name: "Artist Submissions",
        url: "artist-submission"
      },
      {
        name: "House Rules",
        url: "house-rules"
      },
    ];
  }
  close(){
    this.modalService.dismissAll()
  }
}
