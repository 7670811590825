import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class staticsService {
  mobilePageTitleListener = new EventEmitter<any>();

  constructor(private http: HttpClient) { }

  listen(): Observable<any> {
    return this.mobilePageTitleListener.asObservable();
  }

  updateMobileTitle(mobileTitle: any) {    
    this.mobilePageTitleListener.emit(mobileTitle);
  }

  getstaticssdata(){
    return this.http.get<any>(environment.localUrl + 'statics/list');
  }
  getAboutUsStatics(){
    return this.http.get<any>(environment.localUrl + 'website/about-us');
  }
  getCareerStatics(){
    return this.http.get<any>(environment.localUrl + 'website/careers');
  }
  getArtistsStatics(){
    return this.http.get<any>(environment.localUrl + 'website/artists');
  }
  getHouseRulesStatics(){
    return this.http.get<any>(environment.localUrl + 'website/houserules');
  }
 



  

}