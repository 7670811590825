import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MainthemeComponent } from "./layouts/maintheme/maintheme.component";
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common'
import { SideNavbarComponent } from "./layouts/maintheme/side-navbar/side-navbar.component";
import { ToastrModule } from "ngx-toastr";







@NgModule({
  declarations: [AppComponent, MainthemeComponent , SideNavbarComponent ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule ,BrowserAnimationsModule,HttpClientModule , CommonModule,
    ToastrModule.forRoot({
      positionClass :'toast-top-right'
    })

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
