import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainthemeComponent } from './layouts/maintheme/maintheme.component';
import { PUBLIC_ROUTES } from "./shared/routes/public.routes";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'homepage',
    pathMatch: 'full',
  },
  { path: '', component: MainthemeComponent, data: { title: 'full Views' }, children: PUBLIC_ROUTES },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
